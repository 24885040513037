.body-content > section {
  margin-bottom: 72px;
}

.team-members__member {
  margin-bottom: 54px;
}

.page--home .body-content > section h2 {
  text-align: center;
}

section.intro {
  max-width: 960px;
  padding: 48px 54px;
  @include box-shadow();
  background: #FFFEF3;
  text-align: center;
  p {
    font-size: 18px;
  }
}

section.contact {
  background: $blue-light;
  background: linear-gradient(146deg,  rgba(#D9B7C5,.8) 0.00%, rgba(202, 232, 238,.8) 50%, #FDD3BC 100.00%), #0E7E95;
  padding: 72px 54px;
  @include box-shadow();
  border-radius: 18px;
}

.angle-bg {
  background: url(/assets/images/angle-bg.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.testimonials {
  min-height: 400px;
  margin-top: 120px;
}

section.contact {
  text-align: center;
}
