#contact  {
  background-color: lighten($blue-light, 10%);
  @include box-shadow();
  padding: 60px 5vw;
  h2 {
    font-size: 28px;
  }
  @media all and (min-width: 769px) {
    display: flex;
    div.form {
      padding-right: 60px;
      width: 65%;
    }
    div.description {
      margin: -60px -5vw -60px 0;
      width: 55%;
    }
  }
  .form {
    h2 {
      margin-top: 0;
    }
    label {
      font-size: 14px;
      font-weight: 700;
      line-height: 1.125;
      margin: .4em 0;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
    input[type=text], input[type=email], label, textarea {
      display: block;
      width: 100%;
    }
    input[type=text], input[type=email], textarea {
      background: #F6FCFE;
      border: none;
      border-radius: 3px;
      margin-bottom: 16px;
      border: 1px solid lighten($blue-dark, 30%);
    }
    input[type=text], input[type=email] {
      height: 22px;
      padding: 20px 4px;
    }
  }
  div.description {
    background: lighten($blue-light, 5%);
    padding: 40px;
  }
}
