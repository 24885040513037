.button, input[type=submit] {
  color: #ffffff !important;
  background-color: $blue-dark;
  padding: 18px 22px;
  text-align: center;
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: background-color .3s ease !important;
  border: none;
  cursor: pointer;
  &:hover {
    border: none !important;
    background-color: lighten($blue-dark, 8%);
  }
  &.light {
    background: #ffffff;
    border: $blue-dark;
  }
}

.contact-cta a {
  &:after {
    content: '';
    width: 20px;
    height: 20px;
    display: block;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23ffffff' d='M2.01 21L23 12 2.01 3 2 10l15 2-15 2z'/%3E%3C/svg%3E%0A");
    float: right;
    margin-left: 4px;
    position: relative;
    top: -6px;
    margin-bottom: -6px;
    transform: rotate(-15deg);
  }
  &:hover:after {
    transform: rotate(-25deg);
  }
}
