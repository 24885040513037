#page-intro {
  padding: 80px 0;
  background-color: $blue-light;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  video {
    object-fit: cover;
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .content {
    position: relative;
    h1 {
      position: relative;
      line-height: .875;
      margin-bottom: 0;
    }
    p:first-of-type {
      margin-top: 1.5em;
      font-size: 24px;
      line-height: 1.25;
    }
  }
}

.page--home #page-intro {
  //background-image: url('/assets/images/intro-background.svg');
  .content {
    max-width: 680px;
  }
  h1 {
    @media all and (min-width: 1020px) {
      width: 600px;
    }
  }

}

.page--contact #page-intro {
  padding: 20px 0;
  h1 {
    line-height: 1.2;
    margin: 0;
  }
}
