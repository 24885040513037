#main-nav {
  ul {
    list-style: none;
    text-align: right;
    li {
      a {
        color: $text-color;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 700;
        transition: .3s border-color;
        border-color: transparent;
        &:hover {
          border-bottom: 3px solid $blue-light;
        }
      }
      &.contact a {
        color: $orange;
      }
    }
  }
}

#footer-nav {
  ul {
    margin: 0;
    padding: 0;
    li {
      padding: 0;
      margin: 0 0 12px 0;
      list-style: none;
      max-width: 144px;
      a {
        color: #ffffff;
        font-weight: 400;
        //text-decoration: none;
      }
    }
  }
  > ul > li {
    @media all and (min-width: 768px) {
      margin: 0 33px;
    }
    > a {
      font-weight: 700;
      text-transform: uppercase;
    }
    > ul {
      margin-top: 12px;
    }
  }
}
