body {
  margin: 0;
}

.inner {
  width: 96%;
  max-width: 1120px;
  margin: 0 auto;
  @media all and (min-width: 768px) {
    width: 92%;
  }
}

body:not(.page--full-width) .body-content.inner {
  max-width: 800px;
}
