.testimonials ul {
  width: 96%;
  max-width: 800px;
  margin: 0 auto;
  .slick-slide {
    margin: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  li {
    @media all and (min-width: 768px) {
      padding: 0 80px;
    }
  }
  .slick-arrow {
    background: rgba(#ffffff,.6);
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-size: 40px 40px;
    position: absolute;
    background-repeat: no-repeat;
    background-position: center center;
    top: calc(50% - 20px);
    padding: 0;
    text-indent: -1000em;
    z-index: 1;
    outline: none;
    border: none;
    cursor: pointer;
    &.slick-prev {
      background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M15.41%2016.09l-4.58-4.59%204.58-4.59L14%205.5l-6%206%206%206z%22%2F%3E%3C%2Fsvg%3E');
      left: -10px;
      @media all and (min-width: 768px) {
        left: 16px;
      }
    }
    &.slick-next {
      background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M8.59%2016.34l4.58-4.59-4.58-4.59L10%205.75l6%206-6%206z%22%2F%3E%3C%2Fsvg%3E');
      right: -10px;
      @media all and (min-width: 768px) {
        right: 16px;
      }
    }
  }
}

.testimonial {

}
