.services {
  .service {
    svg {
      max-width: 120px;
      margin: 0 auto;
    }
    p {
      margin: 0;
    }
    p a {
      &:after {
        content: '';
        display: inline-block;
        position: relative;
        top: 3px;
        width: 16px;
        height: 16px;
        background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M8.59%2016.34l4.58-4.59-4.58-4.59L10%205.75l6%206-6%206z%22%2F%3E%3C%2Fsvg%3E');
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
}

.page--home .services {
  @media all and (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 60px;
    row-gap: 40px;
  }
  .service {
    text-align: center;
    @media all and (min-width: 768px) {
      width: calc(33% - 60px);
    }
    .icon {
      aspect-ratio: 2 / 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
}

.page--services .services {
  .service {
    display: flex;
    justify-content: space-around;
    align-items: center;
    .icon {
      width: 200px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      svg {
        width: 100%;
      }
    }
    .copy {
      width: calc(100% - 200px);
    }
  }
}
