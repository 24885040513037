@mixin underline() {
  transition: .3s border-color;
  border-color: transparent;
  &:hover {
    border-bottom: 3px solid $blue-light;
  }
}

@mixin box-shadow() {
  -webkit-box-shadow: 2px 1px 8px 0px rgba(85,85,85,0.3);
  -moz-box-shadow: 2px 1px 8px 0px rgba(85,85,85,0.3);
  box-shadow: 2px 1px 8px 0px rgba(85,85,85,0.3);
}
