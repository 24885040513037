#page-footer {
  background: $blue-dark;
  padding: 40px 0;
  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    #footer-logo {
      margin-bottom: 36px;
    }
    > nav > ul > li {
      margin-bottom: 32px;
    }
    @media all and (min-width: 768px) {
      flex-direction: row;
      align-items: flex-start;
      #footer-logo {
        margin-right: 33px;
        margin-bottom: 0;
      }
      nav > ul {
        display: flex;
      }
    }
  }
}
