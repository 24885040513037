$blue-light: #CAE8EE;
$blue-dark: #324255;
$orange: #D64E03;
$almost-black: #161616;

$text-color: $almost-black;
$link-color: $orange;
$inter: 'Inter', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;


// text sizing

$font-size--s: 16px;
$font-size--m: 24px;
$font-size--l: 36px;
$font-size--xl: 54px;
$font-size--xxl: 81px;
