@import url('https://rsms.me/inter/inter.css');
//@import url('https://rsms.me/inter/inter-display.css');

body {
  font-family: $inter;
}

h1, .h1 {
  font-weight: 800;
  font-size: $font-size--xxl;
  line-height: .875;
  letter-spacing: -4px;
  margin-top: 0;
}

h1.section-title {
  @extend .h3;
}

h2, .h2 {
  font-size: $font-size--xl;
  letter-spacing: .5px;
  line-height: 1.2;
  font-weight: 800;
  margin: .625em 0 .25em 0;
}

section > h2:first-of-type {
  margin-top: 0;
}

.case-studies {
  h2 {
    font-size: $font-size--l;
  }
  h3 {
    font-size: $font-size--s;
  }
}

h2+p, h2+ul {
  margin-top: 0;
}

h3, .h3 {
  font-size: $font-size--m;
  letter-spacing: .5px;
  line-height: 1.2;
  font-weight: 800;
  margin: .625em 0 0 0;
  text-transform: uppercase;
}

h4 {
  font-weight: 800;
  margin: 0;
}

ul {
  padding-left: 18px;
  li {
    padding-left: 12px;
    margin-bottom: 8px;
  }
}

p {
  line-height: 1.25;
}

a, a:link, a:visited, a:active, a:focus {
  color: $orange;
  font-weight: 700;
  text-decoration: none;
  transition: .3s border-color;
  border-color: transparent;
  &:hover {
    border-bottom: 3px solid $blue-light;
  }
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}
