.case-studies__study .inner {
  @media all and (min-width: 768px) {
    display: flex;
    margin: 80px auto;
    gap: 6vw;
  }
  .copy {
    width: 45%;
    h2 {
      max-width: 400px;
    }
    .more-link {
      text-decoration: none;
      color: $text-color;
      font-weight: 600;
      @include underline();
    }
  }
  @media all and (min-width: 768px) {
    .project-image {
      width: 55%;
    }
  }
}

.case-studies__study:nth-of-type(even) .inner {
  flex-direction: row-reverse;
}

.case-studies__study.box-shadow .project-image img {
  @include box-shadow();
}

.case-studies__study.heading-top {
  .heading-wrapper.inner {
    margin-bottom: 0;
  }
  .content-wrapper {
    margin-top: 48px;
  }
  .inner {
    justify-content: center;
  }
}

#readyrosie-case-study .project-image {
  max-width: 380px;
}

#progress-texas-case-study .heading-wrapper h2 {
  max-width: 605px;
  text-align: center;
}

#flagstaff-case-study .content-wrapper {
  align-items: center;
  .copy {
    width: 33%;
    max-width: 288px;
  }
  .project-image {
    width: 66%;
  }
}
